<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('report.daily_market_retail_price_report') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <!-- <b-overlay :show="loading"> -->
          <b-col lg="12" sm="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                <b-row>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Area Type" vid="area_type_id">
                      <b-form-group
                        label-for="area_type_id"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('bazarMonitoring.area_type')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.area_type_id"
                          :options="areaTypeList"
                          id="area_type_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="search.area_type_id === 1 || search.area_type_id === 2 || search.area_type_id === 3">
                    <ValidationProvider name="Division" vid="division_id">
                      <b-form-group
                        label-for="division_id"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.division')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.division_id"
                          :options="divisionList"
                          id="division_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="search.area_type_id === 1 || search.area_type_id === 2 || search.area_type_id === 3">
                    <ValidationProvider name="District" vid="district_id">
                      <b-form-group
                        label-for="district_id"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.district')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.district_id"
                          :options="districtList"
                          id="district_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="search.area_type_id === 1">
                    <ValidationProvider name="City Corporation" vid="city_corporation_id">
                      <b-form-group
                        label-for="city_corporation_id"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.city_corporation')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.city_corporation_id"
                          :options="corporationList"
                          id="city_corporation_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="search.area_type_id === 2 || search.area_type_id === 3">
                    <ValidationProvider name="Upazila" vid="upazila_id">
                      <b-form-group
                        label-for="upazila_id"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.upazila')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.upazila_id"
                          :options="upazilaList"
                          id="upazila_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="search.area_type_id === 2">
                    <ValidationProvider name="Pauroshoba" vid="pauroshoba_id">
                      <b-form-group
                        label-for="pauroshoba_id"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.pouroshova')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.pauroshoba_id"
                          :options="pauroshobaList"
                          id="pauroshoba_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="search.area_type_id === 3">
                    <ValidationProvider name="Union" vid="union_id">
                      <b-form-group
                        label-for="union_id"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.union')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.union_id"
                          :options="unionList"
                          id="union_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="0" vid="market_directory_id">
                      <b-form-group
                          label-for="market_directory_id"
                          slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('bazarMonitoring.market_name')}}
                      </template>
                      <b-form-select
                          plain
                          v-model="search.market_directory_id"
                          :options="marketDirectoryList"
                          id="market_directory_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                      >
                          <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Date" vid="date" rules="required">
                      <b-form-group
                        label-for="date"
                      >
                        <template v-slot:label>
                          {{ $t('globalTrans.date') }} <span class="text-danger">*</span>
                        </template>
                          <date-picker
                            id="date"
                            v-model="search.date"
                            class="form-control"
                            :placeholder="$t('globalTrans.select')"
                            :locale="currentLocale"
                          >
                          </date-picker>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="0" vid="price_category_type">
                      <b-form-group
                          label-for="price_category_type"
                          slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('report.price_category_type')}}
                      </template>
                      <b-form-select
                          plain
                          v-model="search.price_category_type"
                          :options="priceCategoryTypeList"
                          id="price_category_type"
                          :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </b-form-select>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-button type="submit" size="sm" variant="primary" class="mt-20">
                      <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </ValidationObserver>
          </b-col>
          <!-- </b-overlay> -->
        </b-row>
      </template>
      <!-- search section end -->
    </card>
    <b-row>
      <b-col md="12" v-if="showData">
        <body-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('report.daily_market_retail_price_report') }}</h4>
          </template>
          <template v-slot:headerAction>
            <a href="javascript:" class="btn-add" @click="pdfExport">
                {{ $t('globalTrans.print') }}
            </a>
            <a href="javascript:" class="btn-add ml-2" @click="excelExport">
                {{  $t('globalTrans.export_excel') }}
            </a>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col>
                  <list-report-head :base-url="bazarMonitoringServiceBaseUrl" uri="/configuration/report-head/detail" :org-id="orgId">
                    <template v-slot:projectNameSlot>
                      {{ }}
                    </template>
                    {{ search.price_category_type === 1 ? $t('report.daily_market_retail_price_report_title') : $t('report.daily_market_wholesale_price_report_title') }} <br>
                    {{ currentLocale === 'en' ? dateFormatEn : dateFormatBn }}
                  </list-report-head>
                </b-col>
              </b-row>
              <template>
                <!-- <div class="text-black">
                  <b-row>
                    <b-col md="3" lg="3">
                      <span>{{ $t('globalTrans.division') }}: </span><strong>{{ search.division_id ? ($i18n.locale === 'en' ? searchHeaderData.division_name : searchHeaderData.division_name_bn) : $t('globalTrans.all') }}</strong>
                    </b-col>
                    <b-col md="3" lg="3">
                      <span>{{ $t('globalTrans.district') }}: </span><strong>{{ search.district_id  ?  ($i18n.locale === 'en' ? searchHeaderData.district_name : searchHeaderData.district_name_bn) : $t('globalTrans.all') }}</strong>
                    </b-col>
                    <template v-if="search.area_type_id === 1">
                      <b-col md="3" lg="3">
                        <span>{{ $t('globalTrans.city_corporation') }}: </span><strong>{{ search.city_corporation_id  ?  ($i18n.locale === 'en' ? searchHeaderData.city_corporation_name : searchHeaderData.city_corporation_name_bn) : $t('globalTrans.all') }}</strong>
                      </b-col>
                    </template>
                    <template v-else>
                      <b-col md="3" lg="3">
                        <span>{{ $t('globalTrans.upazila') }}: </span><strong>{{ search.upazila_id  ?  ($i18n.locale === 'en' ? searchHeaderData.upazila_name : searchHeaderData.upazila_name_bn) : $t('globalTrans.all') }}</strong>
                      </b-col>
                      <b-col md="3" lg="3" v-if="search.area_type_id === 2">
                        <span>{{ $t('globalTrans.pouroshova') }}: </span><strong>{{ search.pauroshoba_id ? ($i18n.locale === 'en' ? searchHeaderData.pauroshoba_name : searchHeaderData.pauroshoba_name_bn) : $t('globalTrans.all') }}</strong>
                      </b-col>
                      <b-col md="3" lg="3" v-else>
                        <span>{{ $t('globalTrans.union') }}: </span><strong>{{ search.union_id ? ($i18n.locale === 'en' ? searchHeaderData.union_name : searchHeaderData.union_name_bn) : $t('globalTrans.all') }}</strong>
                      </b-col>
                    </template>
                  </b-row>
                  <b-row>
                    <b-col md="3" lg="3">
                      <span>{{ $t('bazarMonitoring.market_name') }}: </span><strong>{{ search.market_directory_id ? ($i18n.locale === 'en' ? searchHeaderData.market_name_en : searchHeaderData.market_name_bn) : $t('globalTrans.all') }}</strong>
                    </b-col>
                    <b-col md="3" lg="3">
                      <span>{{ $t('globalTrans.date') }}: </span><strong>{{ search.date|dateFormat }}</strong>
                    </b-col>
                  </b-row>
                </div> -->
                <b-row class="justify-content-end">
                  <b-col md="4" class="text-right" v-if="search.price_category_type == 2">
                    <strong>{{ $t('report.measurement_unit_text') }}</strong>
                  </b-col>
                  <b-col md="3" class="text-right">
                    <span>{{ $t('globalTrans.date') }}: </span><strong>{{ search.date|dateFormat }}</strong>
                  </b-col>
                </b-row>
                <b-row >
                  <b-col>
                    <template v-if="listData.length">
                      <b-table-simple class="tg mt-3" bordered striped hover small caption-top responsive :emptyText="$t('globalTrans.noDataFound')">
                        <b-thead>
                          <b-tr>
                            <b-th style="width:13%" class="text-center">{{ $t('bazarMonitoring.commodity_name') }}</b-th>
                            <b-th style="width:13%" class="text-center" v-if="search.price_category_type === 1">{{ $t('bazarMonitoring.measurement_unit') }}</b-th>
                            <template v-for="(item, index) in frequencyDateArr">
                              <b-th colspan="2" :key="index" style="width:20%" class="text-center">
                                {{ currentLocale === 'en' ? item.frequency_text_en : item.frequency_text_bn }} {{ item.frequency_date | dateFormat }}
                              </b-th>
                              <b-th :key="'mon-' + index" v-if="item.frequency_type === 4">
                                {{ $t('report.months_price') }}
                              </b-th>
                              <b-th :key="'year-' + index" v-if="item.frequency_type === 5">
                                {{ $t('report.years_price') }}
                              </b-th>
                            </template>
                          </b-tr>
                          <!-- <b-tr>
                            <template v-for="(info, index) in frequencyDateArr">
                              <b-th class="text-center" :key="'H' + index">{{ $t('bazarMonitoring.highest') }}</b-th>
                              <b-th class="text-center" :key="'L' + index">{{ $t('bazarMonitoring.lowest') }}</b-th>
                              <b-th class="text-center" :key="'tk' + index"><i class="text-success ri-arrow-down-line"></i><i class="text-danger ri-arrow-up-line"></i>{{ $t('report.tk') }}</b-th>
                              <b-th class="text-center" :key="'%' + index"><i class="text-success ri-arrow-down-line"></i><i class="text-danger ri-arrow-up-line"></i>{{ $t('report.%') }}</b-th>
                            </template>
                          </b-tr> -->
                        </b-thead>
                        <b-tbody>
                          <template v-for="(commodityGroup, commodityGroupIndex) in listData">
                            <b-tr :key="'g-' + commodityGroupIndex" class="">
                              <b-td>{{ currentLocale === 'en' ? commodityGroup.commodity_group_name_en : commodityGroup.commodity_group_name_bn }}</b-td>
                              <b-td v-if="search.price_category_type === 1"></b-td>
                              <template v-for="(item, index) in frequencyDateArr">
                                <b-td class="text-center" :key="'L' + index">{{ commodityGroupIndex === 0 ? $t('bazarMonitoring.lowest') : '' }}</b-td>
                                <b-td class="text-center" :key="'H' + index">{{ commodityGroupIndex === 0 ? $t('bazarMonitoring.highest') : '' }}</b-td>
                                <b-td :key="'freq-' + index" v-if="[4, 5].includes(item.frequency_type)">
                                  <template v-if="commodityGroupIndex === 0">(+)/(-)</template>
                                </b-td>
                              </template>
                            </b-tr>
                            <template v-for="(commodityName, commodityNameIndex) in commodityGroup.commodity_name_info">
                              <b-tr :key="'G-' + commodityGroupIndex + '-N-' + commodityNameIndex">
                                <b-td>{{ ($i18n.locale === 'en') ? commodityName.commodity_name_en : commodityName.commodity_name_bn }}</b-td>
                                <b-td class="text-center" v-if="search.price_category_type === 1">{{ ($i18n.locale === 'en') ? commodityName.unit_name_en : commodityName.unit_name_bn }}</b-td>
                                <template v-for="(frequencyItem, frequencyIndex) in commodityName.frequency_type_info">
                                  <b-td v-if="frequencyItem.frequency_type === 1" :key="'G-' + commodityGroupIndex + '-N-' + commodityNameIndex + '-f-1-'+frequencyIndex" class="text-center" @click="showMarketList(1, commodityName.commodity_name_id)">{{ parseFloat(frequencyItem.lowest_price_amount) > 0 ? $n(frequencyItem.lowest_price_amount) : '' }}</b-td>
                                  <b-td v-else :key="'G-' + commodityGroupIndex + '-N-' + commodityNameIndex + '-f-1-'+frequencyIndex" class="text-center">{{ parseFloat(frequencyItem.lowest_price_amount) ? $n(frequencyItem.lowest_price_amount) : '' }}</b-td>
                                  <b-td v-if="frequencyItem.frequency_type === 1" :key="'G-' + commodityGroupIndex + '-N-' + commodityNameIndex + '-f-2-'+frequencyIndex" class="text-center" @click="showMarketList(2, commodityName.commodity_name_id)">{{ parseFloat(frequencyItem.highest_price_amount) > 0 ? $n(frequencyItem.highest_price_amount) : '' }}</b-td>
                                  <b-td v-else :key="'G-' + commodityGroupIndex + '-N-' + commodityNameIndex + '-f-2-'+frequencyIndex" class="text-center">{{ parseFloat(frequencyItem.highest_price_amount) > 0 ? $n(frequencyItem.highest_price_amount) : '' }}</b-td>
                                  <b-td :key="'G-' + commodityGroupIndex + '-N-' + commodityNameIndex + '-f-3-'+frequencyIndex" class="text-center" v-if="[4, 5].includes(parseInt(frequencyItem.frequency_type))">{{ getFluctuationRatePercentage(frequencyItem) }}</b-td>
                                </template>
                              </b-tr>
                            </template>
                          </template>
                          <b-tr>
                            <b-td :colspan="search.price_category_type === 1 ? 12 : 13" align="center">
                              {{ $t('report.market_msg') }} {{ showMarketNames(marketIdArr) }}
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                      <div class="comment-area">
                        <h5 class="mb-1">{{ $t('globalTrans.comments') + ' (' + $t('report.within_one_week') + ')' }}:</h5>
                        <!-- Condition 1 -->
                        <template v-if="commodityNamesPriceIncreased.length && commodityNamesPriceDecreased.length && (commodityNameIdArr.length > (commodityNamesPriceIncreased.length + commodityNamesPriceDecreased.length))">
                          <p>
                            ({{ $n(1) }})
                            <template v-if="currentLocale === 'en'">The price of {{ showCommodityNames(commodityNamesPriceIncreased) }} has increased.</template>
                            <template v-else>{{ showCommodityNames(commodityNamesPriceIncreased) }} এর  মূল্য বৃদ্ধি পেয়েছে।</template>
                          </p>
                          <p>
                            ({{ $n(2) }})
                            <template v-if="currentLocale === 'en'">The price of {{ showCommodityNames(commodityNamesPriceDecreased) }} has decreased.</template>
                            <template v-else>{{ showCommodityNames(commodityNamesPriceDecreased) }} এর  মূল্য হ্রাস পেয়েছে।</template>
                          </p>
                          <p>({{ $n(3) }}) {{ $t('report.price_unchanged_msg') }}</p>
                        </template>
                        <!-- Condition 2 -->
                        <template v-else-if="commodityNamesPriceIncreased.length && commodityNamesPriceDecreased.length === 0 && (commodityNameIdArr.length > (commodityNamesPriceIncreased.length + commodityNamesPriceDecreased.length))">
                          <p>
                            ({{ $n(1) }})
                            <template v-if="currentLocale === 'en'">The price of {{ showCommodityNames(commodityNamesPriceIncreased) }} has increased.</template>
                            <template v-else>{{ showCommodityNames(commodityNamesPriceIncreased) }} এর  মূল্য বৃদ্ধি পেয়েছে।</template>
                          </p>
                          <p>({{ $n(2) }}) {{ $t('report.price_unchanged_msg') }}</p>
                        </template>
                        <!-- Condition 3 -->
                        <template v-else-if="commodityNamesPriceIncreased.length === 0 && commodityNamesPriceDecreased.length && (commodityNameIdArr.length > (commodityNamesPriceIncreased.length + commodityNamesPriceDecreased.length))">
                          <p>
                            ({{ $n(1) }})
                            <template v-if="currentLocale === 'en'">The price of {{ showCommodityNames(commodityNamesPriceDecreased) }} has decreased.</template>
                            <template v-else>{{ showCommodityNames(commodityNamesPriceDecreased) }} এর  মূল্য হ্রাস পেয়েছে।</template>
                          </p>
                          <p>({{ $n(2) }}) {{ $t('report.price_unchanged_msg') }}</p>
                        </template>
                        <template v-else-if="commodityNamesPriceIncreased.length === 0 && commodityNamesPriceDecreased.length === 0 && (commodityNameIdArr.length > (commodityNamesPriceIncreased.length + commodityNamesPriceDecreased.length))">
                          <p>
                          <p>({{ $n(1) }}) {{ $t('report.product_price_unchanged_msg') }}</p>
                        </template>
                        <!-- Condition 4 -->
                        <template v-else-if="commodityNamesPriceIncreased.length && commodityNamesPriceDecreased.length && (commodityNameIdArr.length === (commodityNamesPriceIncreased.length + commodityNamesPriceDecreased.length))">
                          <p>
                            ({{ $n(1) }})
                            <template v-if="currentLocale === 'en'">The price of {{ showCommodityNames(commodityNamesPriceIncreased) }} has increased.</template>
                            <template v-else>{{ showCommodityNames(commodityNamesPriceIncreased) }} এর  মূল্য বৃদ্ধি পেয়েছে।</template>
                          </p>
                          <p>
                            ({{ $n(2) }})
                            <template v-if="currentLocale === 'en'">The price of {{ showCommodityNames(commodityNamesPriceDecreased) }} has decreased.</template>
                            <template v-else>{{ showCommodityNames(commodityNamesPriceDecreased) }} এর  মূল্য হ্রাস পেয়েছে।</template>
                          </p>
                          <p>({{ $n(3) }}) {{ $t('report.product_price_changed_msg') }}</p>
                        </template>
                      </div>
                      <div class="commodity-name-area mt-4">
                        <p>{{ $t('report.commodity_name_msg_increased') }}</p>
                        <b-table-simple bordered striped hover small caption-top responsive :emptyText="$t('globalTrans.noDataFound')">
                          <b-thead>
                            <b-tr>
                              <b-th class="text-center">{{ $t('bazarMonitoring.commodity_name') }}</b-th>
                              <b-th class="text-center">{{ $t('bazarMonitoring.measurement_unit') }}</b-th>
                              <b-th class="text-center" colspan="2">{{ $t('report.todays_price_tk') }}</b-th>
                              <b-th class="text-center" colspan="2">{{ $t('report.last_weeks_price_tk') }}</b-th>
                              <b-th class="text-center">{{ $t('report.decrease_increase') }}</b-th>
                              <b-th class="text-center">{{ $t('globalTrans.comments') }}</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <template v-if="fluctuatedListIncreased.length">
                              <b-tr v-for="(item, index) in fluctuatedListIncreased" :key="'fl-'+ index">
                                <b-td>{{ getCommodityName(item.commodity_name_id) }}</b-td>
                                <b-td class="text-center">{{ getMeasurementUnitName(item.measurement_unit_id) }}</b-td>
                                <template v-for="(frequencyItem, frequencyIndex) in item.frequency_type_info">
                                  <b-td :key="'N-' + commodityNameIndex + '-l-'+frequencyIndex" class="text-center">{{ parseFloat(frequencyItem.lowest_price_amount) > 0 ? $n(frequencyItem.lowest_price_amount) : '' }}</b-td>
                                  <b-td :key="'N-' + commodityNameIndex + '-h-'+frequencyIndex" class="text-center">{{ parseFloat(frequencyItem.highest_price_amount) ? $n(frequencyItem.highest_price_amount) : '' }}</b-td>
                                  <b-td :key="'N-' + commodityNameIndex + '-f-'+frequencyIndex" class="text-center" v-if="frequencyItem.frequency_type === 3">{{ getFluctuationRatePercentage(frequencyItem) }}</b-td>
                                  <b-td :key="'N-' + commodityNameIndex + '-com-'+frequencyIndex" class="text-center" v-if="frequencyItem.frequency_type === 3">
                                    {{ getFluctuatedComment(frequencyItem)}}
                                  </b-td>
                                </template>
                              </b-tr>
                            </template>
                            <template v-else>
                              <b-tr>
                                <b-td colspan="8" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                              </b-tr>
                            </template>
                          </b-tbody>
                        </b-table-simple>
                      </div>

                      <div class="commodity-name-area mt-4">
                        <p>{{ $t('report.commodity_name_msg_decreased') }}</p>
                        <b-table-simple bordered striped hover small caption-top responsive :emptyText="$t('globalTrans.noDataFound')">
                          <b-thead>
                            <b-tr>
                              <b-th class="text-center">{{ $t('bazarMonitoring.commodity_name') }}</b-th>
                              <b-th class="text-center">{{ $t('bazarMonitoring.measurement_unit') }}</b-th>
                              <b-th class="text-center" colspan="2">{{ $t('report.todays_price_tk') }}</b-th>
                              <b-th class="text-center" colspan="2">{{ $t('report.last_weeks_price_tk') }}</b-th>
                              <b-th class="text-center">{{ $t('report.decrease_increase') }}</b-th>
                              <b-th class="text-center">{{ $t('globalTrans.comments') }}</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <template v-if="fluctuatedListDecreased.length">
                              <b-tr v-for="(item, index) in fluctuatedListDecreased" :key="'fl-'+ index">
                                <b-td>{{ getCommodityName(item.commodity_name_id) }}</b-td>
                                <b-td class="text-center">{{ getMeasurementUnitName(item.measurement_unit_id) }}</b-td>
                                <template v-for="(frequencyItem, frequencyIndex) in item.frequency_type_info">
                                  <b-td :key="'N-' + commodityNameIndex + '-l-'+frequencyIndex" class="text-center">{{ parseFloat(frequencyItem.lowest_price_amount) >0 ? $n(frequencyItem.lowest_price_amount) : '' }}</b-td>
                                  <b-td :key="'N-' + commodityNameIndex + '-h-'+frequencyIndex" class="text-center">{{ parseFloat(frequencyItem.highest_price_amount) > 0 ? $n(frequencyItem.highest_price_amount) : '' }}</b-td>
                                  <b-td :key="'N-' + commodityNameIndex + '-f-'+frequencyIndex" class="text-center" v-if="frequencyItem.frequency_type === 3">{{ getFluctuationRatePercentage(frequencyItem) }}</b-td>
                                  <b-td :key="'N-' + commodityNameIndex + '-com-'+frequencyIndex" class="text-center" v-if="frequencyItem.frequency_type === 3">
                                    {{ getFluctuatedComment(frequencyItem)}}
                                  </b-td>
                                </template>
                              </b-tr>
                            </template>
                            <template v-else>
                              <b-tr>
                                <b-td colspan="8" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                              </b-tr>
                            </template>
                          </b-tbody>
                        </b-table-simple>
                      </div>
                    </template>
                    <template v-else>
                      <h3 class="text-center mt-5">{{ $t('globalTrans.noDataFound') }}</h3>
                    </template>
                  </b-col>
                </b-row>
                <template v-if="designationInformation && designationInformation.id">
                  <b-row class="mt-4">
                    <b-col offset-md="2" md="4" class="text-center">
                      <span>({{ currentLocale === 'en' ? designationInformation.person_one_name_en : designationInformation.person_one_name_bn }})</span><br>
                      <span>{{ currentLocale === 'en' ? designationInformation.person_one_designation_en : designationInformation.person_one_designation_bn }}</span>
                    </b-col>
                    <b-col offset-md="3" md="3" class="text-center">
                      <span>({{ currentLocale === 'en' ? designationInformation.person_two_name_en : designationInformation.person_two_name_bn }})</span><br>
                      <span>{{ currentLocale === 'en' ? designationInformation.person_two_designation_en : designationInformation.person_two_designation_bn }}</span>
                    </b-col>
                  </b-row>
                  <b-row class="mt-5">
                    <b-col>
                      <p v-html="currentLocale === 'en' ? designationInformation.designation_description_en : designationInformation.designation_description_bn"></p>
                    </b-col>
                  </b-row>
                </template>
              </template>
            </b-overlay>
          </template>
        </body-card>
      </b-col>
    </b-row>
    <b-modal id="modal-details" size="md" :title="$t('report.market_price') + ' ('+ getCommodityName(detailsSearch.commodity_name_id) +')'" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :search="detailsSearch" :price-type="priceType" :key="detailsSearch" :callSearchData="searchData" />
    </b-modal>
  </div>
</template>
<script>
import RestApi, { bazarMonitoringServiceBaseUrl } from '@/config/api_config'
import { dailyMarketRetailPriceReport } from '../../api/routes'
import ListReportHead from '@/components/custom/BazarMonitoringReportHead.vue'
import Details from './Details.vue'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

const today = new Date().toISOString().substr(0, 10)
export default {
  components: {
    ListReportHead, Details
  },
  data () {
    return {
      bazarMonitoringServiceBaseUrl: bazarMonitoringServiceBaseUrl,
      sortBy: '',
      search: {
        commodity_group_id: 0,
        commodity_name_id: 0,
        market_directory_id: 0,
        frequency_type: 0,
        frequency_type_check: [],
        price_category_id: 0,
        area_type_id: 0,
        city_corporation_id: 0,
        pauroshoba_id: 0,
        division_id: 0,
        district_id: 0,
        upazila_id: 0,
        union_id: 0,
        date: today,
        price_category_type: 1
      },
      searchHeaderData: {
        price_category_name: '',
        price_category_name_bn: '',
        frequency_name: '',
        frequency_name_bn: '',
        division_name: '',
        division_name_bn: '',
        district_name: '',
        district_name_bn: '',
        city_corporation_name: '',
        city_corporation_name_bn: '',
        upazila_name: '',
        upazila_name_bn: '',
        union_name: '',
        union_name_bn: '',
        pauroshoba_name: '',
        pauroshoba_name_bn: '',
        market_name_en: '',
        market_name_bn: '',
        price_collection_date: ''
      },
      editItemId: '',
      officer: {},
      sortDesc: true,
      sortDirection: 'desc',
      marketDirectoryList: [],
      districtList: [],
      upazilaList: [],
      corporationList: [],
      unionList: [],
      wardList: [],
      pauroshobaList: [],
      testdatas: [],
      commodityNameList: [],
      priceCategoryListData: [],
      loading: false,
      showData: false,
      frequencyDateArr: [],
      listData: [],
      dailyMarketRetailPriceReport: dailyMarketRetailPriceReport,
      org_name: '',
      org_name_bn: '',
      orgId: 7,
      marketNames: '',
      marketIdArr: [],
      commodityNamesPriceIncreased: [],
      commodityNamesPriceDecreased: [],
      commodityNameIdArr: [],
      fluctuatedList: [],
      fluctuatedListIncreased: [],
      fluctuatedListDecreased: [],
      dateFormatEn: '',
      dateFormatBn: '',
      designationInformation: {},
      priceType: 1,
      detailsSearch: {}
    }
  },
  created () {
    const org = this.$store.state.CommonService.commonObj.orgProfileList.find(obj => obj.value === this.orgId)
    this.org_name = org?.text_en
    this.org_name_bn = org?.text_bn
  },
  mounted () {
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.search = Object.assign({}, this.search, {
        division_id: this.addressCustomUser.division_id,
        district_id: this.addressCustomUser.district_id,
        upazila_id: this.addressCustomUser.upazila_id,
        market_directory_id: this.addressCustomUser.market_id
      })
    }
  },
  computed: {
    addressCustomUser: function () {
      return this.$store.state.BazarMonitoringService.commonObj.addressCustomUser
    },
    currentLocale () {
      return this.$i18n.locale
    },
    priceCategoryList () {
      return this.$store.state.BazarMonitoringService.commonObj.priceCategoryList.filter(item => item.status === 1)
    },
    divisionList: function () {
      return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
    },
    commodityGroupList: function () {
       return this.$store.state.BazarMonitoringService.commonObj.commodityGroupList.filter(item => item.status === 1)
    },
    frequencyTypeList: function () {
       return this.$store.state.BazarMonitoringService.commonObj.frequencyTypeList
    },
    areaTypeList: function () {
      const list = this.$store.state.commonObj.areaTypeList
      return list.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    priceCategoryTypeList () {
      return [
        { value: 1, text_en: 'Retail', text_bn: 'খুচরা', text: this.currentLocale === 'en' ? 'Retail' : 'খুচরা' },
        { value: 2, text_en: 'Wholesale', text_bn: 'পাইকারি', text: this.currentLocale === 'en' ? 'Wholesale' : 'পাইকারি' }
      ]
    }
  },
  watch: {
    'search.area_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.marketDirectoryList = this.getAreaMarketList(newVal)
      } else {
        this.marketDirectoryList = []
      }
    },
    'search.division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.districtList = this.getDistrictList(newVal)
        this.marketDirectoryList = this.getDivMarketList(newVal)
      } else {
        this.districtList = []
        this.marketDirectoryList = []
      }
    },
    'search.district_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
        this.corporationList = this.getcityCorporationList(newVal)
        this.marketDirectoryList = this.getDisMarketList(newVal)
      } else {
        this.upazilaList = []
        this.corporationList = []
        this.marketDirectoryList = []
      }
    },
    'search.upazila_id': function (newVal, oldVal) {
      if (newVal) {
        this.unionList = this.getUnionList(newVal)
        this.pauroshobaList = this.getPauroshobaList(newVal)
        this.marketDirectoryList = this.getUpaMarketList(newVal)
      } else {
        this.unionList = []
        this.pauroshobaList = []
        this.marketDirectoryList = this.getDefaultMarketList(this.search)
      }
    },
    'search.city_corporation_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.marketDirectoryList = this.getCityMarketList(newVal)
      } else {
        this.marketDirectoryList = []
      }
    },
    'search.pauroshoba_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.marketDirectoryList = this.getPauroMarketList(newVal)
      } else {
        this.marketDirectoryList = []
      }
    },
    'search.union_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.marketDirectoryList = this.getUniMarketList(newVal)
      } else {
        this.marketDirectoryList = []
      }
    }
  },
  methods: {
    async pdfExport () {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: this.orgId }, this.search)
      this.loading = true
      const result = await RestApi.getPdfData(bazarMonitoringServiceBaseUrl, dailyMarketRetailPriceReport, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      // this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      this.loading = false
    },
    async excelExport () {
      const params = Object.assign({ request_type: 'excel', local: this.$i18n.locale, org_id: this.orgId }, this.search)
      // this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      this.loading = true
      const result = await RestApi.getPdfData(bazarMonitoringServiceBaseUrl, dailyMarketRetailPriceReport, params)
      var blob = new Blob([result], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      var url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'daily-market-retail-price-report.xlsx')
      document.body.appendChild(link)
      link.click()
      // this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      this.loading = false
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      if (this.search.price_category_id) {
        const priceCategoryObj = this.$store.state.BazarMonitoringService.commonObj.priceCategoryList.find(data => data.value === this.search.price_category_id)
        this.searchHeaderData.price_category_name = priceCategoryObj !== undefined ? priceCategoryObj.text_en : ''
        this.searchHeaderData.price_category_name_bn = priceCategoryObj !== undefined ? priceCategoryObj.text_bn : ''
      }
      if (this.search.frequency_type) {
        const frequencyType = this.$store.state.BazarMonitoringService.commonObj.frequencyTypeList.find(data => data.value === this.search.frequency_type)
        this.searchHeaderData.frequency_name = frequencyType !== undefined ? frequencyType.text_en : ''
        this.searchHeaderData.frequency_name_bn = frequencyType !== undefined ? frequencyType.text_bn : ''
      }
      if (this.search.division_id) {
        const divisionObj = this.$store.state.CommonService.commonObj.divisionList.find(division => division.value === this.search.division_id)
        this.searchHeaderData.division_name = divisionObj !== undefined ? divisionObj.text_en : ''
        this.searchHeaderData.division_name_bn = divisionObj !== undefined ? divisionObj.text_bn : ''
      }
      if (this.search.district_id) {
        const districtObj = this.$store.state.CommonService.commonObj.districtList.find(district => district.value === this.search.district_id)
        this.searchHeaderData.district_name = districtObj !== undefined ? districtObj.text_en : ''
        this.searchHeaderData.district_name_bn = districtObj !== undefined ? districtObj.text_bn : ''
      }
      if (this.search.city_corporation_id) {
        const cityCorporationObj = this.$store.state.CommonService.commonObj.cityCorporationList.find(obj => obj.value === this.search.city_corporation_id)
        this.searchHeaderData.city_corporation_name = cityCorporationObj !== undefined ? cityCorporationObj.text_en : ''
        this.searchHeaderData.city_corporation_name_bn = cityCorporationObj !== undefined ? cityCorporationObj.text_bn : ''
      }
      if (this.search.upazila_id) {
        const upazilaObj = this.$store.state.CommonService.commonObj.upazilaList.find(upazila => upazila.value === this.search.upazila_id)
        this.searchHeaderData.upazila_name = upazilaObj !== undefined ? upazilaObj.text_en : ''
        this.searchHeaderData.upazila_name_bn = upazilaObj !== undefined ? upazilaObj.text_bn : ''
      }
      if (this.search.pauroshoba_id) {
        const pauroshobaObj = this.$store.state.CommonService.commonObj.municipalityList.find(obj => obj.value === this.search.pauroshoba_id)
        this.searchHeaderData.pauroshoba_name = pauroshobaObj !== undefined ? pauroshobaObj.text_en : ''
        this.searchHeaderData.pauroshoba_name_bn = pauroshobaObj !== undefined ? pauroshobaObj.text_bn : ''
      }
      if (this.search.union_id) {
        const unionObj = this.$store.state.CommonService.commonObj.unionList.find(union => union.value === this.search.union_id)
        this.searchHeaderData.union_name = unionObj !== undefined ? unionObj.text_en : ''
        this.searchHeaderData.union_name_bn = unionObj !== undefined ? unionObj.text_bn : ''
      }
      if (this.search.market_directory_id) {
        const marketDirectoryObj = this.$store.state.BazarMonitoringService.commonObj.marketDirectoryList.find(market => market.value === this.search.market_directory_id)
        this.searchHeaderData.market_name_en = marketDirectoryObj !== undefined ? marketDirectoryObj.text_en : ''
        this.searchHeaderData.market_name_bn = marketDirectoryObj !== undefined ? marketDirectoryObj.text_bn : ''
      }
      this.showData = true
      const params = Object.assign({}, this.search)
      // this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: true })
      this.loading = true
      const result = await RestApi.getData(bazarMonitoringServiceBaseUrl, dailyMarketRetailPriceReport, params)
      if (result.success) {
        const frequencyArr = []
        for (const frequency in result.frequency_date_info) {
          if (Object.prototype.hasOwnProperty.call(result.frequency_date_info, frequency)) {
            const frequencyDate = result.frequency_date_info[frequency]
            let frequencyItem = {}
            if (parseInt(frequency) === 1) {
              frequencyItem = {
                frequency_type: parseInt(frequency),
                frequency_text_en: this.$t('report.today_price_tk', 'en'),
                frequency_text_bn: this.$t('report.today_price_tk', 'bn'),
                frequency_date: frequencyDate
              }
              frequencyArr.push(frequencyItem)
            } else if (parseInt(frequency) === 2) {
              frequencyItem = {
                frequency_type: parseInt(frequency),
                frequency_text_en: this.$t('report.last_day_price', 'en'),
                frequency_text_bn: this.$t('report.last_day_price', 'bn'),
                frequency_date: frequencyDate
              }
              frequencyArr.push(frequencyItem)
            } else if (parseInt(frequency) === 3) {
              frequencyItem = {
                frequency_type: parseInt(frequency),
                frequency_text_en: this.$t('report.last_week_price_tk', 'en'),
                frequency_text_bn: this.$t('report.last_week_price_tk', 'bn'),
                frequency_date: frequencyDate
              }
              frequencyArr.push(frequencyItem)
            } else if (parseInt(frequency) === 4) {
              frequencyItem = {
                frequency_type: parseInt(frequency),
                frequency_text_en: this.$t('report.last_month_price_tk', 'en'),
                frequency_text_bn: this.$t('report.last_month_price_tk', 'bn'),
                frequency_date: frequencyDate
              }
              frequencyArr.push(frequencyItem)
            } else if (parseInt(frequency) === 5) {
              frequencyItem = {
                frequency_type: parseInt(frequency),
                frequency_text_en: this.$t('report.last_year_price_tk', 'en'),
                frequency_text_bn: this.$t('report.last_year_price_tk', 'bn'),
                frequency_date: frequencyDate
              }
              frequencyArr.push(frequencyItem)
            }
          }
        }
        this.frequencyDateArr = frequencyArr
        this.search.frequency_type_check = this.search.frequency_type
        // this.$store.dispatch('setList', this.getCustomizedList(result.data))
        this.listData = this.getCustomizedList(result.data)
        this.marketIdArr = result.market_ids
        this.commodityNamesPriceIncreased = result.commodity_name_increased
        this.commodityNamesPriceDecreased = result.commodity_name_decreased
        this.commodityNameIdArr = result.commodity_name_id_arr
        this.fluctuatedList = result.fluctuated_list
        this.fluctuatedListIncreased = result.fluctuated_list_increased
        this.fluctuatedListDecreased = result.fluctuated_list_decreased
        this.dateFormatEn = result.date_format_en
        this.dateFormatBn = result.date_format_bn
        this.designationInformation = result.designation_information
      } else {
        this.listData = []
        this.marketIdArr = []
        this.commodityNamesPriceIncreased = []
        this.commodityNamesPriceDecreased = []
        this.fluctuatedList = []
        this.fluctuatedListIncreased = []
        this.fluctuatedListDecreased = []
        this.dateFormatEn = ''
        this.dateFormatBn = ''
        this.designationInformation = {}
      }
      this.loading = false
    },
    getCustomizedList (data) {
      const list = data.map(item => {
        item.commodity_name_info = item.commodity_name_info.map(commodityNameInfo => {
          // const frequencyTypeInfo = []
          // this.frequencyDateArr.forEach((frequencyItem, frequencyIndex) => {
          //   let frequencyInfo
          //   frequencyInfo = commodityNameInfo.frequency_type_info.find(obj => parseInt(obj.frequency_type) === parseInt(frequencyItem.frequency_type))
          //   if (frequencyInfo === undefined) {
          //     frequencyInfo = {
          //       frequency_type: parseInt(frequencyItem.frequency_type),
          //       lowest_price_amount: 0,
          //       highest_price_amount: 0,
          //       fluctuation_rate: 0,
          //       fluctuation_rate_percentage: 0
          //     }
          //   }
          //   frequencyTypeInfo.push(frequencyInfo)
          // })
          // commodityNameInfo.frequency_type_info = frequencyTypeInfo
          const commodityName = this.$store.state.BazarMonitoringService.commonObj.commodityNameList.find(obj => obj.value === parseInt(commodityNameInfo.commodity_name_id))
          const unit = this.$store.state.BazarMonitoringService.commonObj.unitList.find(obj => obj.value === parseInt(commodityNameInfo.measurement_unit_id))
          const customItem = {
            commodity_name_en: commodityName?.text_en,
            commodity_name_bn: commodityName?.text_bn,
            unit_name_en: unit?.text_en,
            unit_name_bn: unit?.text_bn
          }
          return Object.assign({}, commodityNameInfo, customItem)
        })
        const commodityGroup = this.commodityGroupList.find(obj => obj.value === parseInt(item.commodity_group_id))
        const customItem = {
          commodity_group_name_en: commodityGroup?.text_en,
          commodity_group_name_bn: commodityGroup?.text_bn
        }
        return Object.assign({}, item, customItem)
      })
      return list
    },
    getDistrictList (id) {
      return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1 && item.division_id === id)
    },
    getUpazilaList (id) {
      return this.$store.state.CommonService.commonObj.upazilaList.filter(item => item.status === 1 && item.district_id === id)
    },
    getcityCorporationList (id) {
      return this.$store.state.CommonService.commonObj.cityCorporationList.filter(item => item.status === 1 && item.district_id === id)
    },
    getUnionList (upazilaId) {
      return this.$store.state.CommonService.commonObj.unionList.filter(item => item.status === 1 && item.upazila_id === upazilaId)
    },
    getPauroshobaList (id) {
      return this.$store.state.CommonService.commonObj.municipalityList.filter(item => item.status === 1 && item.upazila_id === id)
    },
    getAreaMarketList (id) {
      return this.$store.state.BazarMonitoringService.commonObj.marketDirectoryList.filter(item => item.status === 1 && item.area_type_id === id)
    },
    getDivMarketList (id) {
      return this.$store.state.BazarMonitoringService.commonObj.marketDirectoryList.filter(item => item.status === 1 && item.division_id === id)
    },
    getDisMarketList (id) {
      return this.$store.state.BazarMonitoringService.commonObj.marketDirectoryList.filter(item => item.status === 1 && item.district_id === id)
    },
    getUpaMarketList (id) {
      return this.$store.state.BazarMonitoringService.commonObj.marketDirectoryList.filter(item => item.status === 1 && item.upazila_id === id)
    },
    getUniMarketList (id) {
      return this.$store.state.BazarMonitoringService.commonObj.marketDirectoryList.filter(item => item.status === 1 && item.union_id === id)
    },
    getPauroMarketList (id) {
      return this.$store.state.BazarMonitoringService.commonObj.marketDirectoryList.filter(item => item.status === 1 && item.pauroshoba_id === id)
    },
    getCityMarketList (id) {
      return this.$store.state.BazarMonitoringService.commonObj.marketDirectoryList.filter(item => item.status === 1 && item.city_corporation_id === id)
    },
    getDefaultMarketList (data) {
      return this.$store.state.BazarMonitoringService.commonObj.marketDirectoryList.filter(item => item.status === 1 && item.area_type_id === data.area_type_id && item.division_id === data.division_id && item.district_id === data.district_id)
    },
    getCommodityNameList (id) {
        return this.$store.state.BazarMonitoringService.commonObj.commodityNameList.filter(item => item.status === 1 && item.commodity_group_id === id)
    },
    getFluctuationRatePercentage (frequencyItem) {
      return (parseFloat(Math.abs(frequencyItem.fluctuation_rate)) > 0 ? '(+) ' : (parseFloat(Math.abs(frequencyItem.fluctuation_rate)) < 0 ? '(-) ' : '')) + (parseFloat(Math.abs(frequencyItem.fluctuation_rate)) > 0 ? this.$n(Math.abs(frequencyItem.fluctuation_rate_percentage)) : '')
    },
    showMarketNames (idArr) {
      const arr = []
      idArr.forEach(marketId => {
        const obj = this.$store.state.BazarMonitoringService.commonObj.marketDirectoryList.find(item => item.value === parseInt(marketId))
        const name = this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        arr.push(name)
      })
      return arr.join(',')
    },
    showCommodityNames (idArr) {
      const arr = []
      idArr.forEach(marketId => {
        const obj = this.$store.state.BazarMonitoringService.commonObj.commodityNameList.find(item => item.value === parseInt(marketId))
        const name = this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        arr.push(name)
      })
      return arr.join(',')
    },
    getCommodityName (id) {
      const obj = this.$store.state.BazarMonitoringService.commonObj.commodityNameList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getMeasurementUnitName (id) {
      const obj = this.$store.state.BazarMonitoringService.commonObj.unitList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getFluctuatedComment (frequencyItem) {
      let comment = ''
      const date = this.$options.filters.dateFormat(frequencyItem.date)
      if (this.currentLocale === 'en') {
        const type = frequencyItem.fluctuation_type === 1 ? 'increased' : 'decreased'
        comment = `Price ${type} on ${date}.`
      } else {
        const type = frequencyItem.fluctuation_type === 1 ? 'বৃদ্ধি' : 'হ্রাস'
        comment = `${date} তারিখে মূল্য ${type} পেয়েছে।`
      }
      return comment
    },
    showMarketList (priceType = 1, commodityNameId) {
      this.detailsSearch = Object.assign(this.detailsSearch, this.search, {
        commodity_name_id: commodityNameId
      })
      this.priceType = priceType
      this.$bvModal.show('modal-details')
    }
  }
}
</script>
<style scoped>
  /* .table-responsive {
    max-height: 300px;
    overflow-y: scroll;
  } */
</style>
